
import MYTOOL from '@/utils/myTool'
import axios from "axios";
import { ElMessage } from "element-plus";
//const CancelToken = axios.CancelToken;

import storeInfoFun from "../info"
import router from "@/router";

const defaultHeaders = {
    //"Content-Type": "application/x-www-form-urlencoded", //第一次会发送OPTIONS
    // "Cache-Control":"no-cache",//第一次会发送OPTIONS
    //"Access-Control-Allow-Origin":"http://localhost:8080",
    // "access-control-expose-headers":"*",
    //"Access-Control-Allow-Headers":"*",
    "Accept": "*/*",
    //  "Accept-Encoding":"gzip, deflate",
    //"Connection":"keep-alive",
    //"Set-Cookie":"??????",
    //"Authorization":"?????????",//需要关闭withCredentials
}
//axios.defaults.withCredentials = true;
//axios.defaults.crossDomain =true;


// axios.interceptors.response.use(
//     response => {
//       // 获取响应头部的 set-cookie 字段
//       const cookies = response.headers['Set-Cookie'];
//       console.log("response",response)
//       if (cookies) {
//         // 将 cookie 保存到本地，以便后续使用
//         cookies.forEach(cookie => {
//           document.cookie = cookie;
//         });
//       }
//       return response;
//     },
//     error => {
//       return Promise.reject(error);
//     }
//   );

// 用于存储每个请求的标识和取消函数
const pendingMap = new Map();

function addPending(url) {
    //removePending(url);
   // console.log("addPending url=" + url)
    const controller = new AbortController();
   
    if (!pendingMap.has(url)) {
        // 如果当前请求不在等待中，将其添加到等待中
        pendingMap.set(url, controller);
    }
    if(controller.callbackList == undefined)
        controller.callbackList = []
    if(controller.failbackList == undefined)
        controller.failbackList = []
    return controller;
}


function doPendingCallBack(pending, data)
{
    pending.callbackList.forEach(element => {
        element(data);
    });
    pending.callbackList.length = 0
}

function doPendingFailBack(pending, data)
{
    pending.failbackList.forEach(element => {
        element(data);
    });
    pending.failbackList.length = 0
}


function removeAllPending() {
    pendingMap.forEach((abortController) => {
        if (abortController) {
            abortController.abort();
        }
    });

}

function removePending(url) {

    if (pendingMap.has(url)) {
        // 如果当前请求在等待中，取消它并将其从等待中移除
        const abortController = pendingMap.get(url);
        if (abortController) {
            abortController.abort(url);
        }
        pendingMap.delete(url);
    }
}

function getPending(url,callback,failback) {
   let instance = pendingMap.get(url)
    if(!MYTOOL.isEmpty(instance))
    {
        if(instance.callbackList == undefined)
            instance.callbackList = []
        if(instance.failbackList == undefined)
            instance.failbackList = []

        if(!MYTOOL.isEmpty(callback))
        {
            instance.callbackList.push(callback);
        }
        if(!MYTOOL.isEmpty(failback))
        {
            instance.failbackList.push(failback);
        }
    }
    //console.log(url + "instance==>",instance);
    return instance
}


var Axs = {


    getHeaders(headers)
    {
        if(MYTOOL.isEmpty(headers))
            headers = {}

        let storeInfo = storeInfoFun();
        if (!MYTOOL.isEmpty(storeInfo.token)) {
            headers.Authorization = "Bearer " +  storeInfo.token
        }
        return headers
    },

    // getMultipartHeaders(headers)
    // {
    //     if(MYTOOL.isEmpty(headers))
    //         headers = {}

    //     let storeInfo = storeInfoFun();
    //     if (!MYTOOL.isEmpty(storeInfo.token)) {
    //         headers.Authorization = "Bearer " +  storeInfo.token
    //     }

    //     headers["Content-Type"] = "multipart/form-data"
    //     return headers
    // },

    get(url, params, headers, callback, failback, timeout,noMultiple) {


        if (params == undefined || params == null)
            params = {}
        if (headers == undefined || headers == null)
            headers = defaultHeaders

        if(!noMultiple && !MYTOOL.isEmpty(getPending(url,callback,failback)))
        {
            return;
        }

      //  console.log("url==>",url);
        // let e = new Error(url)
        // console.error(e.stack)
        let pending = null
        if(!noMultiple)
            pending = addPending(url);
      //  let signal = addPending(url);
     
        let _callback = function (req) {
           // console.log("req", req,"url=>" + url);
            if(!noMultiple)
            removePending(url);
            if (!MYTOOL.isEmpty(req.data)) {
                if(Axs.toError(req.data))
                {
                    if(!noMultiple)
                    doPendingFailBack(pending,req.data.message);
                    if (failback != undefined && failback != null)
                        failback(req.data.message);
                    return;
                }
                if(!noMultiple)
                doPendingCallBack(pending,req.data);
                if (callback != undefined && callback != null) {
                        callback(req.data);
                }
            }
            else
            {
                if(!noMultiple)
                doPendingFailBack(pending,req.message);
                if (failback != undefined && failback != null)
                failback(req.message);
                return;
            }
        }

        let _failback = function (error) {
            if(!noMultiple)
            removePending(url);
            console.log("_failback", error)
            if(!noMultiple)
            doPendingFailBack(pending,error);
            if (failback != undefined && failback != null)
                failback(error);
            if(error.message)
                ElMessage({type: "error",message:error.message})
        }

        if (timeout == undefined || timeout == null)
            timeout = 10000;


        // let storeInfo = storeInfoFun();
        // if (!MYTOOL.isEmpty(storeInfo.token)) {
        //     headers.Authorization = "Bearer " +  storeInfo.token
        // }
        headers = Axs.getHeaders(headers)
        console.log("url=>" + url + "   ",noMultiple);
        axios.get(url, {
            params,//url参数
            headers,
            timeout,
            signal:((!noMultiple)?pending.signal:undefined)
            //withCredentials: true,
            //crossDomain: true,
            // cancelToken: cancelToken
        }).then(_callback, _failback); //value.data就是返回的数据



    },

    getAsync(url, params, headers, timeout,noMultiple) {
        return new Promise((resolve,reject)=> {
            Axs.get(url, params, headers, (res)=>{
                resolve(res);
            }, (err)=>{
                resolve(err);
            }, timeout,noMultiple);
        });
    },


    post(url, data, params, headers, callback, failback, timeout,noMultiple) {
        if (params == undefined || params == null)
            params = {}
        if (headers == undefined || headers == null)
            headers = defaultHeaders

        
        if(!noMultiple && !MYTOOL.isEmpty(getPending(url,callback,failback)))
        {
            return;
        }

        let pending = null
        if(!noMultiple)
            pending = addPending(url);

        let _callback = function (req) {
            if(!noMultiple)
                removePending(url);
            if (!MYTOOL.isEmpty(req.data)) {
                if(Axs.toError(req.data))
                {
                    if(!noMultiple)
                        doPendingFailBack(pending,req.data.message);
                    if (failback != undefined && failback != null)
                    failback(req.data.message);
                    return;
                }
                console.log(req.data)
                if(!noMultiple)
                    doPendingCallBack(pending,req.data);
                if (callback != undefined && callback != null)
                    callback(req.data);
            }
            else
            {
                if(!noMultiple)
                    doPendingFailBack(pending,req.message);
                if (failback != undefined && failback != null)
                failback(req.message);
                return;
            }

        }

        let _failback = function (error) {
            if(!noMultiple)
                removePending(url);
            console.log("_failback", error)
            if(!noMultiple)
                doPendingFailBack(pending,error);
            if (failback != undefined && failback != null)
                failback(error);
            if(error.message)
                ElMessage({type: "error",message:error.message})
        }

        if (timeout == undefined || timeout == null)
            timeout = 10000;

        // const CancelToken = axios.CancelToken;
        // const source = CancelToken.source();

        // let storeInfo = storeInfoFun();
        // if (!MYTOOL.isEmpty(storeInfo.token)) {
        //     headers.Authorization = "Bearer " +  storeInfo.token
        // }

        headers = Axs.getHeaders(headers)


        axios.post(url,
            data,
            {
                timeout,
                params,//url参数
                headers,
                signal:((!noMultiple)?pending.signal:undefined)
                //  cancelToken: source.token
            }
        ).then(_callback, _failback); //value.data就是返回的数据
        // //下载文件，回调
        // onDownloadProgress: function ({loaded, total, progress, bytes, estimated, rate, download = true}) {
        //     // Do whatever you want with the Axios progress event
        // },
        // //上传文件回调
        // onUploadProgress: function ({loaded, total, progress, bytes, estimated, rate, upload = true}) {
        //     // Do whatever you want with the Axios progress event
        // },

    },

    postAsync(url, data, params, headers, timeout,noMultiple) {
        return new Promise((resolve,reject)=> {
            Axs.post(url, data, params, headers,  (res)=>{
                resolve(res);
            }, (err)=>{
                resolve(err);
            }, timeout,noMultiple);
        });
    },


    put(url, data, params, headers, callback, failback, timeout,noMultiple) {
        if (params == undefined || params == null)
            params = {}
        if (headers == undefined || headers == null)
            headers = defaultHeaders

        if(!noMultiple && !MYTOOL.isEmpty(getPending(url,callback,failback)))
        {
            return;
        }

        let pending = null
        if(!noMultiple)
            pending = addPending(url);

        let _callback = function (req) {
            if(!noMultiple)
            removePending(url);
            if (!MYTOOL.isEmpty(req.data)) {
                if(Axs.toError(req.data))
                {
                    if(!noMultiple)
                    doPendingFailBack(pending,req.data.message);
                    if (failback != undefined && failback != null)
                    failback(req.data.message);
                    return;
                }
                console.log(req.data)
                if(!noMultiple)
                doPendingCallBack(pending,req.data);
                if (callback != undefined && callback != null)
                    callback(req.data);
            }
            else
            {
                if(!noMultiple)
                doPendingFailBack(pending,req.message);
                if (failback != undefined && failback != null)
                failback(req.message);
                return;
            }
        }

        let _failback = function (error) {
            if(!noMultiple)
            removePending(url);
            console.log("_failback", error)
            if(!noMultiple)
            doPendingFailBack(pending,error);
            if (failback != undefined && failback != null)
                failback(error);
            if(error.message)
                ElMessage({type: "error",message:error.message})
        }


        if (timeout == undefined || timeout == null)
            timeout = 10000;

        // const CancelToken = axios.CancelToken;
        // const source = CancelToken.source();
        // let storeInfo = storeInfoFun();
        // if (!MYTOOL.isEmpty(storeInfo.token)) {
        //     headers.Authorization = "Bearer " +  storeInfo.token
        // }

        headers = Axs.getHeaders(headers)


     axios.put(url,
            data,
            {
                timeout,
                params,//url参数
                headers,
                signal:((!noMultiple)?pending.signal:undefined)
                //   cancelToken: source.token
            }
        ).then(_callback, _failback); //value.data就是返回的数据
        // //下载文件，回调
        // onDownloadProgress: function ({loaded, total, progress, bytes, estimated, rate, download = true}) {
        //     // Do whatever you want with the Axios progress event
        // },
        // //上传文件回调
        // onUploadProgress: function ({loaded, total, progress, bytes, estimated, rate, upload = true}) {
        //     // Do whatever you want with the Axios progress event
        // },

    },

    putAsync(url, data, params, headers, timeout,noMultiple) {
        return new Promise((resolve,reject)=> {
            Axs.put(url, data, params, headers,  (res)=>{
                resolve(res);
            }, (err)=>{
                reject(err);
            }, timeout,noMultiple);
        });
    },


    toError(reqData) {
        let status = reqData.status;
        let message = reqData.message;
        let isError = false;
        let storeInfo = storeInfoFun();
        switch (status) {
            case 401:
                message = "token过期";
                storeInfo.loginLoadding = false
                router.replace('/login');
                isError = true;
                break;
            case 403:
                message = "token过期";
                storeInfo.loginLoadding = false
                router.replace('/login');
                isError = true;
                break;
            case 404:
                message = "访问地址错误";
                storeInfo.loginLoadding = false
                router.replace('/login');
                isError = true;
                break;
            case 405:
                message = "账号或者token错误";
                storeInfo.loginLoadding = false
                router.replace('/login');
                isError = true;
                break;
            case 500:
                isError = true;
                break;
            case 501:
                if (!MYTOOL.isEmpty(reqData.nanoid)) {
                    localStorage.setItem("nanoid", reqData.nanoid);
                }
                isError = true;
                break;
            default:
                break;
        }
        if(isError && !MYTOOL.isEmpty(message))
        ElMessage({type: "error",message})
       // console.log("isError==>",isError)
        return isError;
    },


    downLoadOpen(url)
    {
        let storeInfo = storeInfoFun();
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.setRequestHeader('Authorization',"Bearer " +  storeInfo.token);
        xhr.send();
    },


    async uploadAsync(url,file,feild,callback,failback,params,headers,timeout)
    {
 
        if (headers == undefined || headers == null)
            headers = defaultHeaders

        if (timeout == undefined || timeout == null)
        timeout = 10000;

        headers = Axs.getHeaders(headers)

        let signal = addPending(url);
        const formData = new FormData();
       // const file = this.$refs.fileInput.files[0];
        formData.append(feild, file);
        await axios.post(url, formData,
            {
                timeout,
                params,//url参数
                headers,
                signal: signal
                //  cancelToken: source.token
            })
          .then(req => {
            console.log('上传成功',req);
         
            if (!MYTOOL.isEmpty(req.data)) {
                if(Axs.toError(req.data))
                {
                    if (failback != undefined && failback != null)
                    failback(req.data.message);
                    return;
                }
                if (callback != undefined && callback != null) {
                    callback(req.data);
                }
            }
            else
            {
                if (failback != undefined && failback != null)
                    failback(req.message);
                return;
            }

          })
          .catch(error => {
            console.error('上传失败：', error);
            if(failback!=undefined)
                failback(error)
            if(error.message)
                ElMessage({type: "error",message:error.message})
          });
    }

    
}

export default Axs